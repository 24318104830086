import React from 'react';
import { BrowserRouter, Routes, Route, useRoutes } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { persistor, store } from './redux/store.js'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from "react-toastify";

// import config
import { socket } from './config/socketConnectivity';

// import Context
import SocketContext from './context/SocketContext';

// helper route
import HelperRoute from './components/Route/HelperRoute';
import PwaModal from './components/PwaModal.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#7EC6EF',
    },
    secondary: {
      main: '#7EC6EF',
    },
  }
});
const AppRoutes = () => {
  return useRoutes(App);
};
root.render(
  // <React.StrictMode>
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <SocketContext.Provider value={{ socket }}>
            <ThemeProvider theme={theme}>
              <PwaModal />
              <ToastContainer />
              <HelperRoute />
              <AppRoutes />
            </ThemeProvider>
          </SocketContext.Provider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </StyledEngineProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

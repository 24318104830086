// import packages
import axios from 'axios';
import { store } from "../redux/store";
import { useSelector, useDispatch } from 'react-redux';
import { clearWallet } from "../redux/wallet/walletSlice";
import { clearUser } from "../redux/user/userSlice";
// import lib
import config from './index';
import { getAuthToken, removeAuthToken } from '../lib/localStorage';
import isLogin from '../lib/isLogin';

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common['Authorization'] = getAuthToken();

export const setAuthorization = (token) => {
    axios.defaults.headers.common['Authorization'] = token;
}

export const removeAuthorization = () => {
    delete axios.defaults.headers.common["Authorization"];
}

export const handleResp = (respData, type = 'success') => {
    try {
        const { token } = store.getState()?.user;

        if (token && type == 'error' && respData && respData.response && respData.response.status == 401) {
            store.dispatch(clearUser())
            store.dispatch(clearWallet())
            window.location.href = '/login'
            return true
        }
    } catch (err) {
        return false
    }
}
axios.interceptors.request.use(function (config) {
    const { token } = store.getState()?.user;
    config.headers.Authorization = token;
    return config;
});
export default axios;
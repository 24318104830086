// import package
import io from 'socket.io-client';
import { decodeJwt } from '../api/jsonWebToken';
import { getAuthToken } from '../lib/localStorage';
// import store from '../store';

// import lib
import config from './index';


const socket = io(config.SOCKET_URL);

const createSocketUser = (userId) => {
    try {
        // console.log(userId, 'userID')
        socket.emit('CREATEROOM', userId)
    } catch (err) {
        console.log(err, 'errro')
    }
}

socket.on("disconnect", () => {
    console.log("Disconnect enter")
    // decodeJwt(getAuthToken(), store.dispatch)
});

export {
    socket,
    createSocketUser
}
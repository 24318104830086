import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser: null,
    error: null,
    loading: false,
    profile: {},
    token: "",
    login: false,
    userSetting:{},
    siteSetting: {},
    maintenanceMode:false,
};

// Reducer 
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUser: (state, action) => {
            state.profile = null;
            state.userSetting = null;
            state.token = null;
            state.loading = false;
            state.error = null;
            state.login = false;
        },
        signInSuccess: (state, action) => {
            state.profile = action.payload.result;
            state.userSetting = action.payload.userSetting;
            state.token = action.payload.token;
            state.loading = false;
            state.error = null;
            state.login = true;
        },
        signInFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        updateUserStart: (state) => {
            state.loading = true;
        },
        updateUserSuccess: (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.error = null;
        },
        updateSiteSetting: (state, action) => {
            state.siteSetting = action.payload;
        },
        setAccountData: (state,action) => {
            state.profile = action.payload;
        },
        deleteUserSuccess: (state) => {
            state.currentUser = null;
            state.loading = false;
            state.error = null;
        },
        deleteUserFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        signOutUserStart: (state) => {
            state.loading = true;
        },
        signOutUserSuccess: (state, action) => {
            state.currentUser = null;
            state.loading = false;
            state.error = null;
        },
        signOutUserFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        setMaintenanceMode: (state, action) => {
            state.maintenanceMode = action.payload;
            state.loading = false;
        },
    },
});

export const {
    signInStart,
    signInSuccess,
    clearUser,
    updateUserStart,
    updateUserSuccess,
    updateUserFailure,
    updateSiteSetting,
    setAccountData,
    deleteUserFailure,
    signOutUserStart,
    signOutUserSuccess,
    signOutUserFailure,
    setMaintenanceMode
} = userSlice.actions;

export default userSlice.reducer;
// import package
import React, { useEffect, useContext,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import SocketContext from '../../context/SocketContext';

// import action
import { viewUserProfile, logout, getSiteSetting,getMaintenanceMode } from '../../api/users';
import { getAssetData, getCurrency, getPriceConversion } from '../../api/walletAction';
import { FetchunReadNotice } from '../../api/users';
import { createSocketUser } from "../../config/socketConnectivity"

import { currency, priceConversion, userWalletList } from "../../redux/wallet/walletSlice";


const HelperRoute = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();


    const socketContext = useContext(SocketContext);
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    // redux-state
    const { login, profile } = useSelector(state => state.user);

    // function
    useEffect(() => {
        if (login) {
            viewUserProfile(dispatch)
            getAssetData(dispatch)
            createSocketUser(profile?.userId)
        }
    }, [login, location.pathname])

    useEffect(() => {
        if (login) {
            createSocketUser(profile?.userId)
        }
    }, [login])
    useEffect(() => {
        // if (!(currencyOption && currencyOption.length > 0)) {
        getCurrency(dispatch);
        // }
        getPriceConversion(dispatch);
        getSiteSetting(dispatch)
        getMaintenanceMode(dispatch)
    }, []);

    useEffect(() => {

        socketContext.socket.on('notice', (result) => {
            FetchunReadNotice(dispatch, result)
        })

        socketContext.socket.on('priceMarketlist', (result) => {
            dispatch(priceConversion(result))
            // FetchunReadNotice(dispatch, result)
        })
        // socketContext.socket.on('NEW_DEPOSIT', (result) => {
        //     getAssetData(dispatch)
        // })
        socketContext.socket.on('FORCE_LOGOUT', (result) => {
            if (login) {
                logout(navigate, dispatch)
            }
        })
        socketContext.socket.on("maintenanceMode", async (maintenance) => {
            setMaintenanceMode(maintenance);
          });

    }, [login])

    return <div />
}

export default HelperRoute;